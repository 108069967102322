<template>
  <div class="wrapper">
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="checkoutCodeImage" slot-scope="{row}" :src="row.checkoutCodeImage || ''" :src-list="[row.checkoutCodeImage]" />
      <CustomPic slot="photos" slot-scope="{row}" :src="getPhotos(row.task ? row.task.photos : '', 0)" :src-list="getPhotos(row.task ? row.task.photos : '')" />
      <template slot="operating" slot-scope="{row}">
        <el-button size="small" type="text" @click="handleDetail(row.taskId)">任务信息</el-button>
      </template>
    </CustomList>
    <Form ref="Form" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './form'
import QRCode from 'qrcode'
export default {
  name: 'ParticipationRecord',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      listQuery: {
        param: {
          claimantUserId: this.$route.params.claimantUserId
        }
      },
      tableColumns: [
        {
          title: '状态',
          formatter: (row, column) => {
            switch (row.status) {
              case 0:
                return '已认领'
              case 1:
                return '已完成'
              default:
                return ''
            }
          },
          minWidth: 100
        },
        {
          title: '分类',
          formatter: (row, column) => {
            return row.task ? row.task.categoryName : ''
          },
          minWidth: 100
        },
        {
          title: '签到码',
          slot: 'checkoutCodeImage'
        },
        {
          title: '发布人信息',
          formatter: (row, column) => {
            let initiatorUserTypeText = ''
            let initiatorName = ''
            if (row.task) {
              initiatorName = row.task.initiatorName
              switch (row.task.initiatorUserType) {
                case '0':
                  initiatorUserTypeText = '服务企业'
                  break
                case '1':
                  initiatorUserTypeText = '用户'
                  break
                case '2':
                  initiatorUserTypeText = '日间照料中心'
                  break
                case '3':
                  initiatorUserTypeText = '系统账户'
                  break
                default:
                  initiatorUserTypeText = ''
                  break
              }
            }
            return initiatorUserTypeText ? `${initiatorName} <br/> ${initiatorUserTypeText}` : initiatorName
          },
          minWidth: 120
        },
        {
          title: '任务信息',
          formatter: (row, column) => {
            let text = ''
            if (row.task) {
              text = row.task.title || ''
              text += row.task.title && row.task.intro ? ' <br/> ' : ''
              text += row.task.intro || ''
            }
            return text
          },
          minWidth: 200
        },
        {
          title: '图片',
          slot: 'photos'
        },
        {
          title: '地点',
          formatter: (row, column) => {
            return row.task ? row.task.address : ''
          },
          minWidth: 150
        },
        {
          title: '联系人',
          formatter: (row, column) => {
            let text = ''
            if (row.task) {
              text = row.task.contactPerson || ''
              text += row.task.contactPerson && row.task.contactPhone ? ' <br/> ' : ''
              text += row.task.contactPhone || ''
            }
            return text
          },
          minWidth: 120
        },
        {
          title: '签到类型',
          formatter: (row, column) => {
            let text = ''
            if (row.task) {
              switch (row.task.checkInType) {
                case 0:
                  text = '活动前'
                  break
                case 1:
                  text = '活动前后'
                  break
                default:
                  text = ''
                  break
              }
            }
            return text
          },
          minWidth: 100
        },
        {
          title: '奖励积分',
          formatter: (row, column) => {
            return row.task ? row.task.credits : ''
          },
          minWidth: 100
        },
        {
          title: '报名人数（已报名/总人数）',
          formatter: (row, column) => {
            return row.task ? `${row.task.claimedNumner || 0}/${row.task.claimantsQuantity || 0}` : ''
          },
          minWidth: 200
        },
        {
          title: '报名时间',
          formatter: (row, column) => {
            return row.task ? `${row.task.claimantsStartTime} <br/> ～ <br/> ${row.task.claimantsEndTime}` : ''
          },
          minWidth: 160
        },
        {
          title: '任务时间',
          formatter: (row, column) => {
            return row.task ? `${row.task.startTime} <br/> ～ <br/> ${row.task.endTime}` : ''
          },
          minWidth: 160
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 100
        }
      ]
    }
  },
  methods: {
    ...mapActions(['pubTaskPublicPageClaimList']),
    getList(params, callback) {
      this.pubTaskPublicPageClaimList(params).then(response => {
        if (response.code === 200) {
          this.getQRCode(response).then(response => {
            callback(response)
          }).catch(() => {
            callback(response)
          })
        } else {
          callback(response)
        }
      }).catch(() => {})
    },
    handleDetail(id) {
      this.$refs.Form.handleDetail(id)
    },
    getPhotos(photos, index) {
      let photosArray = []
      if (photos) {
        photosArray = photos.split(',')
      }

      if (index === 0) {
        return photosArray.length ? photosArray[0] : ''
      } else {
        return photosArray
      }
    },
    getQRCode(response) {
      return new Promise(resolve => {
        response.data.records.forEach(item => {
          item.checkoutCode = item.task ? item.task.checkoutCode : ''
          QRCode.toDataURL(item.checkoutCode || '', { width: 500 }).then(url => {
            item['checkoutCodeImage'] = url
          }).catch(e => {
            item['checkoutCodeImage'] = ''
          })
        })
        resolve(response)
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
